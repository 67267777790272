import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectFabricante', method: 'fabricante.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteExtintor', method: 'agenteExtintor.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgentePropelente', method: 'agentePropelente.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTimportanciaMaterial', method: 'timportanciaMaterial.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idmaterial_sistema', id)
    const resp = await Vue.$api.call('materialSistema.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectDatoMaterialSistema (Vue, idmaterialSistema, idfichaTecnica) {
    let apiCalls = []
    const apiFilterFichaTecnica = new APIFilter()
    apiFilterFichaTecnica
      .addExact('idficha_tecnica', idfichaTecnica)
      .addExact('para_material_sis', true)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectTdatoArticulo', method: 'tdatoArticulo.select', params: { filter: apiFilterFichaTecnica } })
    const apiFilterMaterialSistema = new APIFilter()
    apiFilterMaterialSistema
      .addExact('idmaterial_sistema', idmaterialSistema)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectDatoMaterialSistema', method: 'datoMaterialSistema.select', params: { filter: apiFilterMaterialSistema } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectTDatoArticuloValor (Vue, idtdatoArticulo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idtdato_articulo', idtdatoArticulo)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('tdatoArticuloValor.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
}
